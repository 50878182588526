.questionnaire_table_hover_rows .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
.questionnaire_table_hover_rows .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
.questionnaire_table_hover_rows .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td,
.questionnaire_table_hover_rows .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
    background: unset;
}


.questionnaire_table_row_background {
    background: #fafafa;
}
