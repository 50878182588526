@import '_ant-override-styles';
@import '_normilize';


body {
  margin: 0;
  height: 100%;
}

.wrapper {
  display: flex;
  justify-content: center;
  min-height: 100vh;
  align-items: center;
  background: #b7d2be;
  opacity: 0.9;
}

.ant-layout.layout {
  min-height: 100vh;
  width: 100%;
}

.layout_content{
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0;
}
.layout_content_bg{
  background: inherit;
}

.container__fluid-lg {
  margin: 0 auto;
  max-width: 1200px;
  padding: 0 15px;
}

.container__fluid-sm {
  margin: 0 auto;
  max-width: 900px;
  padding: 0 20px;
}

.footer__simple {
  text-align: center;
  position: fixed;
  bottom: 0;
  color: whitesmoke;
  left: 0;
  background: #011017;
  right: 0;
}

.spinner {
  position: absolute;
  left: 50%;
  top: 40%;
}

.spinner.bottom {
  position: absolute;
  left: 50%;
  top: 55%;
}

.centered_wrapper {
  bottom: 0;
  height: 100px;
  left: 0;
  margin: auto;
  position: absolute;
  top: 0;
  right: 0;
  width: 500px;
  font-size: 30px;
  text-align: center;

  a {
    margin-left: 20px;
  }
}

.page_wrapper_style{
  width: 100%;
  max-width: 85vw;
  height: 100%;
  padding: 24px;
  background: #fff;
}

.ant-space-vertical {
  width:100%;
}

.ant-card.noBg {
  border: 1px solid #997D48;
  background: none;
}

.pre_line_text{
  white-space: pre-line
}

.component {
  &.width100 {
    width: 100%;
  }
  &.flex {
    display: flex;
  }
  &.items_end {
   align-items: end;
  }
  &.justify_items_end {
   justify-content: flex-end;
  }
}

.ant-descriptions-item-content{
  white-space: nowrap;
}

.table-column-title-no-break{
  white-space: nowrap;
  min-width: 100px;
}

.checkin_average{
  text-align: center;
}
.form_api_token{
  cursor: default !important;
}

.form_api_token .ant-input-disabled{
  cursor: text !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.ant-scrolling-effect{
  overflow: auto !important;
  width: 100% !important;
}

.macro-ratio-slider {
  display: flex;
  margin-right: 20px;




  .ant-row {
    position: relative;
    flex-direction: column-reverse;

    .ant-form-item-label{
      position: absolute;
      text-align: center;
      left: 10px;
      top: 15px;
      label{
        font-size: 11px;
      }

    }

  }

  .ant-slider-handle{
    //display: none;
  }

  .ant-form-item-control-input{
    position: relative;
  }

  &-weightLoss{

    .ant-slider-dot .ant-slider-dot-active{
      background: green !important;
    }

    .ant-slider-handle{
      z-index: 1;
      background: rgb(108, 188, 108) !important;
    }

    &:hover .ant-slider-rail{
     background: green !important;
    }
    .ant-slider-rail{
     background: green;
    }
  }

  &-maintenance{

    .ant-slider-handle{
      background: orange !important;
      z-index: 1;
    }

    &:hover .ant-slider-rail{
     background: orange !important;
    }
    .ant-slider-rail{
     background: orange;
    }
  }

  &-muscleGain{

    .ant-slider-handle{
      background: red !important;
      z-index: 1;
    }

    &:hover .ant-slider-rail{
      background: red !important;
    }
    .ant-slider-rail{
      background: red;
    }
  }

  .macro-ratio-slider-none .ant-slider-handle {
    //display: none;
  }

  .ant-slider-disabled .ant-slider-mark-text, .ant-slider-disabled .ant-slider-dot {
    cursor: default !important;
  }

  .ant-slider-disabled .ant-slider-handle, .ant-slider-disabled .ant-slider-dot {
    cursor: default;
  }

  .ant-slider{
     .ant-slider-step{
       z-index: -1;
     }
    &-disabled{
      cursor: default;
    }

    min-width: 85px;
    margin-right: 0;
    margin-left: 0;
  }
  p{
    font-size: 10px;
    padding-top: 10px;
    text-align: center;
  }
}

.checkin-row-autogenerated {
  background-color: #ffcece;
}
