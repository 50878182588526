.logo {
    float: left;
    margin: 16px 24px 16px 0;
    background-image: url("../../img/smp-logo.png");
    background-size: contain;
    background-repeat: no-repeat;
    margin-left: 5px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    /*align-items: center;*/
    height: 90px;
}

.ant-row-rtl .logo {
    float: right;
    margin: 16px 0 16px 24px;
}

.site-layout-background {
    background: #fff;
    padding: 0;
}

.meny__top {
    display: flex;
    justify-content: space-between;
}

.logout_tab {
    position: absolute;
    right: 50px;
    top: 25%;
}