.notifications-disabled-tag{
  padding-top: 5px;
  padding-bottom: 5px;
  margin-right: 0px;
}

.notifications-disabled-text {
  font-size: 14px;
  color: black;
}

.client-header {
  padding-right: 0;
}

.client-header .ant-page-header-heading-extra {
  display: flex;
  align-items: center;
}

.pause-reason-tag {
  margin-right: 0;
  font-size: 18px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.pinned-tasks-container {
  width: 100%;
  justify-content: flex-end;
}