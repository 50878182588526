.ant-statistic{
  min-width:60px;
}



.arrow-down {
  height: 0px;
  width: 0px;
  border-top: 10px solid skyblue;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  //margin-left: 50px
  //277 ml
}

.gauge {
  display: flex;
  //width: 500px;
  //283 width
  height: 20px;
  border: 1px solid black;
}

.wloss {
  display: flex;
  width: 23%;
  //flex: 1;
  height: 100%;
  background-color: #d9ead3;
}
.gap {
  width: 20%;
  height: 100%;
  background-color: white;
}
.maintenance {
  display: flex;
  width: 22%;
  height: 100%;
  background-color: #fff2cc;
}
.mgain {
  display: flex;
  width: 20%;
  height: 100%;
  background-color: #f4cccc;
  .section-name{
    right: 0;
    margin-left: -10px;
  }
}

.point{
  text-align:left;
  margin-top: 28px
}

.end-point{
  margin-left: auto;
}

.section-name{
  margin-top: 20px;
  font-size: 10px;
  position: absolute;
}
