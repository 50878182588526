
.dashboard_header_space {
    align-items: center;
}

.dashboard_header_select {
    min-width: 200px;
    width: 100%;
}

.dashboard_body_wrapper {
    margin: 20px 0;
}

.dashboard_chart_area,
.dashboard_kpi_area {
    display: flex;
    align-items: center;
    min-height: 400px;
    height: 100%;
    margin: 0 30px;
}

.dashboard_chart_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-width: 300px;
    margin: 10px;
}

.dashboard_chart_wrapper h3{
    font-weight: bold;
}

.dashboard_chart_bar_wrapper .ant-space-item {
    width: 100%;
}

.dashboard_kpi_card {
    width: 100%;
    height: 100%;
}

.dashboard_kpi_card .ant-card-body{
    display: flex;
    justify-content: space-between;
}

.dashboard_kpi_card .ant-image-img{
    height: 30px;
    width: 30px;
    position: absolute;
    right: 5px;
}

.dashboard_kpi_card .ant-card-body{
    display: block;
}
.dashboard_kpi_card .ant-statistic-title{
    text-align: center;
}

.dashboard_kpi_card .dashboard_kpi_card_label{
    position: absolute;
    right: -5px;
    bottom: 0;
}

.dashboard_kpi_card .ant-image{
    position: absolute;
    right: 0;
    top: 0;
}

.dashboard_kpi_card .ant-card-body::before,
.dashboard_kpi_card .ant-card-body::after{
    display: none;
}

.dashboard_kpi_card_violet {
    background: #c2a6f5;
}

.dashboard_kpi_card_pacific_blue {
    background: #21adc6;
}

.dashboard_kpi_card_fire_sienna_craiola {
    background: #ee8656;
}

.dashboard_kpi_card_fawn {
    background: #f1c56f;
}

.dashboard_kpi_card_cornflower {
    background: #8ba2ee;
}

.dashboard_kpi_card_olive_green {
    background: #a7c979;
}

.dashboard_kpi_card_amaranth_pink {
    background: #ef7cb7;
}

.dashboard_kpi_card_icon{
    font-size: 3em;
    margin: auto 0.1em;
}

.dashboard_kpi_icon_color_violet {
    color: #c2a6f5;
}

.dashboard_kpi_icon_color_pacific_blue {
    color: #21adc6;
}

.dashboard_kpi_icon_color_fire_sienna_craiola {
    color: #ee8656;
}

.dashboard_kpi_icon_color_fawn {
    color: #f1c56f;
}

.dashboard_kpi_icon_color_cornflower {
    color: #8ba2ee;
}

.dashboard_kpi_icon_color_olive_green {
    color: #a7c979;
}

.dashboard_kpi_icon_color_gray {
    color: #797979;
}

.dashboard_kpi_icon_color_amaranth_pink {
    color: #ef7cb7;
}

.dashboard_kpi_icon_color_alizarin_red {
    color: #E32636;
}

.dashboard_widget_column_row {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    padding: 10px;
    border-radius: 10px;
    height: 100%;
}

.dashboard_kpi_card_white .ant-statistic-title{
    color: #000;
}

.dashboard_user_table_header{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 20px 10px;
}

.dashboard_user_table_header .dashboard_user_table_header_item .ant-space-item:last-child{
    position: absolute;
    right: 0;
}

@media  (max-width: 950px){
    .dashboard_header_space{
        flex-direction: column;
        gap: 8px!important;
    }
    .dashboard_user_table_header{
        flex-direction: column;
    }
}

.dashboard_coach_table .ant-table-summary td {
    border-top: 1px solid black;
}

.dashboard_coach_table .ant-table-summary .ant-table-cell:first-child {
    font-weight: 500;
}

.dashboard_coach_table .ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td {
    padding: 16px 10px;
    max-width: 100px;
}

.dashboard_task_header{
    display: flex;
    justify-content: end;
}

.dashboard_header_title{
    position: absolute;
    width: 100%;
}