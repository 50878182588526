.mainHeader {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0;
}

.header_version_space {
    display: flex;
    flex-direction: column;
    color:#fff;
    line-height:  1.5em;
}

.headerDropdown {
    padding: 0 30px;
}