.red-table-cell{
  background: #ffcece;
}

.yellow-table-cell{
  background: #fff1b8;
}

.center-text{
  text-align: center;
}

.actions-column{
  min-width: 146px;
}

.task-table-category {
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}