.coach_sidebar{
    padding:30px 0;
}

.coach_main_layout{
    width:100%;
    max-width: 85vw;
    height:100%;
}
.coach_main_body_row{
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    margin: 20px 0;
}
.coach_main_info_cards_right_row{
    display: flex;
    justify-content: flex-end;
    height: 100%;
}

.coach_main_info_card{
    height: 100%;
}

.coach_main_info_card .ant-statistic-title{
    color: #000;
    font-size: 16px;
    font-weight: 600;
}

.coach_main_info_card .ant-image-img{
    height: 60px;
    width: 60px;
}

.coach_main_body_column_avatar{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.coach_main_body_column_image{
    min-height: 250px;
    object-fit: contain;
}

.coach_main_body_column_info{
    padding: 20px;
}

.coach_main_body_column_info h3{
    font-weight: bold;
}

.coach_main_body_column_info_icon{
    font-size: 20px;
    color: #b6b6b6;
}

.coach_main_body_column_info_upload{
    width: 100%;
    padding: 10px 0;
}

.coach_main_body_column_info_upload_button{
    width: 100%;
    display: flex;
    justify-content: center;
}

.coach_main_body_column_radio{
    display:flex;
    flex-direction: row;
    justify-content:flex-end;
    align-items:center;
    margin-top: 30px;
}

.other_problems,
.other_problems_popover{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.other_problems_popover{
    cursor: pointer;
}

.other_problems_popover_text {
    color: #d4380d;
    padding-top: 5px;
}

.coach_main_body_submit{
    display: flex;
    justify-content: flex-end;
}

.coach_main_body_column_button_submit{
    position: absolute;
    right: 0;
    bottom: 0;
    margin-bottom: 10px;
}