
.notes_list_note_wrapper .ant-comment-actions {
    display: flex;
    align-items: stretch;
}

.notes_list_note_wrapper .ant-comment-actions li {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 5px;

}

.notes_list_note_wrapper .ant-comment-actions{
    overflow-y: auto;
}

.notes_list_note_wrapper .ant-comment-content-detail{
    white-space: pre-wrap;
}

.emoji-mart-search input {
    width: 302px;
}

.notes_list_emoji {
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    padding: 0 10px;
    border-radius: 12px;
    cursor: pointer;
}

.notes_list_emoji_selected {
    background: rgba(29, 155, 209, .1);
    color: rgb(29, 155, 209);
    box-shadow: inset 0 0 0 1px rgb(29, 155, 209);
}

.notes_list_emoji_button {
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 20px;
    cursor: pointer;
}

.notes_list_emoji_counter {
    padding-left: 5px;
    font-size: 16px;
}

.content-wrapper:before{
    padding-right: 15px;
}

.notes_list_emoji_content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
}

.notes_list_pagination {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.notes_feedback .feedback_actions_buttons{
    margin-top: 0;
    position: absolute;
    right: 0;
    top: 0;
}

.feedback_actions_buttons{
    display: flex;
    flex-direction: row;
    align-items: end;
    gap: 10px;
}

.notes_feedback .feedback_actions_template_item{
    height: 24px;
    padding: 5px;
    font-size: 14px;
    margin-top: 2px;
    margin-bottom: 2px;
    line-height: 22px;
    background: #f5f5f5;
    border: 1px solid #f0f0f0;
    border-radius: 2px;
    margin-right: 5px;
}

.notes_list_note_wrapper .emoji-picker-react{
    position: absolute;
    z-index: 9999;
    left: 0;
    bottom: 0;
}

.notes_feedback .feedback_template_item:hover{
    color: #fff;
    background-color: #ffffff;
    border-color: #ffffff;
}

.notes_feedback .ant-comment-actions li{
    margin-bottom: 10px;
    margin-right: 10px;
}

.feedback_actions_buttons button{
    margin-right: 10px;
}

.notes_feedback .ant-comment-content-detail{
    padding-right: 100px;
    padding-top: 5px;
    padding-left: 5px;
}

.ant-modal-body .emoji-picker-react{
    width: 100%;
}

.notes_feedback_classification{
    display: flex;
    margin-bottom: 25px;
}

.notes_feedback_classification .notes_feedback{
    flex: 1;
}

.notes_feedback_classification .ant-card{
    width: 425px;
    border: none;
}

.notes_feedback_classification .ant-card-bordered .ant-card-body{
    border: 1px solid #f0f0f0;
}

.feedback_actions_container{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.feedback_actions_container .ant-card{
    margin-top: 15px;
}

.add_notes_textarea{
    resize: vertical !important;
}