.activity_page_header {
    padding: 0;
}

.activity_active_element_blue_color {
    color: #1890ff;
}

.activity_client_filter_wrapper {
    width: 350px;
}

.activity_client_filter_body {
    padding: 8px;
}

.activity_client_filter_validation_text {
    height: 25px;
    color: #FF0000;
}

.activity-cards-wrapper{
    display: flex;
    align-items: stretch;
}

.activity-card-item{
    width: 100%;
    height: 100%;
}

.activity-card-item .ant-statistic-title{
    color: #000;
}

.activity_client_filter_list_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 8px;
    max-height: 200px;
    max-width: 400px;
}

.activity_client_filter_list {
    width: 100%;
}

.activity_client_filter_checkbox {
    width: 100%;
    margin-right: 10px;
}

.activity_space_footer_wrapper {
    padding: 7px 8px 7px 3px;
    display: flex;
    justify-content: space-between;
}