.mini-dashboard-card {
  border-color: #bfbfbf;
  min-width: 6.5em;
}

.arrow-icon {
  font-size: 16px;
}

.mini-dashboard-card .ant-card-body {
  padding: 0.5em;
}

.mini-dashboard-card .ant-typography {
  margin-bottom: 0;
}

.mini-dashboard-card .card-footer {
  text-align: right;
}

.card-text {
  font-size: smaller;
  margin: 0;
}

.placeholder-field {
  background-color: #fff;
  margin: 15px;
  padding: 15px;
}