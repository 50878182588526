.mobile-layout{
    min-height: 0 !important;
}

/*padding: 0.675em 0.8em 0.675em 0.8em;*/
.cs-conversation{
    padding: 0;
}

.cs-conversation__content{
    padding: 0.675em 0.8em 0.675em 0;
}

.cs-conversation>.cs-avatar{
    /*margin-right: 8px;*/
    margin: 0.675em 0.8em 0.675em 0.8em;
}

.mobile-layout .ant-layout-has-sider{
    min-height: 0 !important;
}

.cs-search__input::placeholder{
    color: rgba(111, 112, 116, 0.61);
}

.cs-conversation__content{
    max-width: 40%;
}

.cs-message__footer .anticon{
    margin-right: 5px;
}

.cs-message__footer{
    justify-content: flex-end;
    margin-top: 10px;
    align-items: center;
}

.chat-mobile .cs-message-input__content-editor{
    font-size: 16px;
}

.chat-mobile .cs-main-container{
    flex-direction: column;
    /*border-radius: 0.7em;*/
}

.chat-mobile .cs-search{
    margin: 0.5em;
}

.chat-mobile .cs-search__input{
    font-size: 16px;
}

.chat-mobile-author-message .ant-popover-inner-content{
    background-color: #EEFFDE;
}


.chat-mobile-outgoing-message .ant-popover-inner-content{
    background-color: #c6e3fa;
}

.chat-mobile-author-message .cs-message__content {
    background-color: #EEFFDE;
}

.chat-mobile-outgoing-message .cs-message__content {
    background-color: #c6e3fa;
}

.cs-main-container .cs-conversation__last-activity-time{
    align-items: end;
    word-break: break-all;
    margin-right: .1em;
    font-size: .6em;
    /*width: 110px;*/
    justify-content: flex-end;
    padding-bottom: 1.4em;
    /*position: absolute;*/
    /*right: 10px;*/
    /*bottom: 10px;*/
}

.cs-main-container .cs-conversation__unread{
    /*bottom: 10px;*/
    top: 1.4em;
    height: 16px;
    right: 70px;
    font-size: .6em;
}

.chat-mobile .scrollbar-container{
    /*height: 76vh;*/
}

.cs-message__header{
    align-items: center;
    margin-left: 0;
}

.cs-conversation__last-activity-time+.cs-conversation__operations{
 align-items: center;
 justify-content: center;
}

.cs-chat__noFound .cs-conversation{
    justify-content: center;
    cursor: default;
}

.cs-conversation-header__content{
    flex-grow: 0;
}

.cs-chat__noFound .cs-conversation:hover{
    background-color: #fff;
}

.cs-chat__noFound .cs-conversation:active{
    background-color: #ffffff !important;
}
.cs-conversation__content{
    margin-right: 0;
}
.cs-chat__noFound .cs-conversation__content{
    margin: 0;
}

.chat-desktop .cs-main-container>.cs-sidebar.cs-sidebar--left{
    width: 720px;
    max-width: 720px;
}

.chat-search{
    display: flex;
    align-items: center;
    flex-direction: column;
}

.chat-search .cs-search{
    flex: 1;
    width: 98%;
}

.chat-search .anticon {
    margin-right: 10px;
    font-size: 24px;
    color: #001529;
    cursor: pointer;
}

.cs-conversation__operations{
    width: 60px;
    margin-left: 0;
    /*margin-right: 10px;*/
    /*border-left: 1px dashed #808080;*/
}

.conversation_action_menu .ant-popover-inner-content{
    padding: 0;
}

.cs-conversation__operations .anticon{
    margin-left: 10px;
    margin-right: 10px;
    font-size: 22px;
}


.flex_between{
    display: flex;
    justify-content: space-between;
}

.conversation_action_menu_button{
    padding: 5px;
    border-top: none;
    border-left: none;
    border-right: none;
}

.conversation_action_menu_button .anticon{
    font-size: 16px;
}

.cs-conversation__last-activity-time+.cs-conversation__operations{
    margin-left: 0;
    /*border-left: solid 1px #d1dbe3;*/
    /*border-bottom: solid 1px #d1dbe3;;*/
    /*background-color: rgba(215, 215, 215, 0.66);*/
}

.conversation_action_menu_main_button{
    width: 50px;
    padding: 0;
    border: none;
    box-shadow: none;
}

/*.ant-btn-text:hover, .ant-btn-text:focus {*/
/*    color: rgba(0, 0, 0, 0.85);*/
/*    background: #f3f8fc;*/
/*}*/
.conversation_action_menu_main_button{
    color: #8b8e92;
    width: 100%;
    height: 100%;
}

.chat-filter-button{
    border: 1px solid #d9d9d9;
    background: transparent;
}

.chat-filter-button-active{
    border: 1px solid #d9d9d9;
    color: #fff;
    background: #1890ff;
}

/*.chat-filter-button-active:hover{*/
/*    transition: all 0.3s linear;*/
/*    background: transparent;*/
/*    !*color: #1890ff;*!*/
/*}*/


.chat-filter-button:focus{
    /*transition: all 0.3s linear;*/
    /*color: #1890ff;*/
    /*border-color: #1890ff;*/
    /*color: #1890ff;*/
}


/*.conversation_action_menu_main_button:hover{*/
/*    background-color: #f3f8fc;*/
/*    transition: none;*/
/*}*/

/*.ps__rail-y{*/
/*    right: -7px !important;*/
/*}*/

/*.ps__thumb-y{*/
/*    width: 8px;*/
/*}*/

/*.ps--active-x>.ps__rail-x, .ps--active-y>.ps__rail-y{*/
/*    right: -7px !important;*/
/*}*/