.client_page_word_wrap .ant-comment-content-detail{
    white-space:pre-wrap;
    word-break: normal;
}

.client-header .ant-page-header-heading-extra {
    width: 75%;
}

.client-header .ant-page-header-content {
    display: flex;
    justify-content: end;
    max-width: 60%;
    margin: 0 0 0 auto;
}

.client-header-extra{
    width: 100%;
}

.client-info-text{
    white-space: break-spaces;
}