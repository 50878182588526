//variables
@bg-dark: #011017;
@bg-success: #669472;
@bg-info: #bae1fd;
@bg-white: white;
@bg-orange: #ed6868;
//color
@color-white: white;
//size
@btn-height: 38px;
@btn-width: 150px;

//btn dark
.ant-btn-dark {
  background-color: @bg-dark;
  color: @color-white;
  border: none;
}

.ant-btn-dark:hover {
  background-color: @bg-dark;
  color: @color-white;
  border: none;
  opacity: 0.9;
}

.ant-btn-dark:focus {
  background-color: @bg-dark;
  color: @color-white;
  border: none;
  opacity: 1;
}

//btn orange
.ant-btn-orange {
  background-color: @bg-orange;
  color: @color-white;
  border: none;
}

.ant-btn-orange:hover {
  background-color: @bg-orange;
  color: @color-white;
  border: none;
  opacity: 0.9;
}

.ant-btn-orange:focus {
  background-color: @bg-orange;
  color: @color-white;
  border: none;
  opacity: 1;
}

.ant-btn-success {
  background-color: @bg-success;
  color: @color-white;
  /*  height: @btn-height;*/
  border: none;
  min-width: @btn-width;
}

.ant-btn-success:hover {
  background-color: @bg-success;
  opacity: 0.9;
  color: @color-white;
  /*  height: @btn-height;*/
  border: none;
}

.ant-btn-success:focus {
  background-color: @bg-success;
  color: @color-white;
  /* height: @btn-height;*/
  border: none;
}

//notifications
.ant-notification-notice {
  box-shadow: rgba(0, 0, 0, 0.25) 0 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.ant-layout-content {
  background: #fff;
  padding: 7px;
}

.ant-input-number {
  width: 100%;
}

.ant-popover-inner{
  max-width: 1200px;
}

.activation_parts_buttons{

  .ant-click-animating-node{
    display: none;
  }

  .ant-btn:focus:enabled{
    color: @bg-success;
    border-color: @bg-success;
  }

  .ant-btn:hover:enabled{
    color: @bg-success;
    border-color: @bg-success;
  }

  .ant-btn-primary{
    background: @bg-success;
    border-color: @bg-success;

    &:disabled{
      color: @color-white;
    }
    //&:disabled{
    //  color: @color-white;
    //}

    &:hover:enabled{
      color: @bg-white;
    }

    &:focus:enabled{
      color: @bg-white;
    }

    &:hover:disabled{
      border-color: @bg-success;
      background: @bg-success;
      color: @color-white;
    }
    &:focus:disabled{
      border-color: @bg-success;
      background: @bg-success;
      color: @color-white;
    }
  }


}
