.macro_modal_button_margin{
    margin-top: 4px;
}

.modal-average-table .ant-modal-body{
    padding: 0;
}

.modal-average-table .ant-table-cell{
    text-align: center;
}