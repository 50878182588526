.react-tel-input .form-control {
  width: 100%;
  border-radius: 0;
  height: 32px;
  padding-top: 16.5px;
  padding-bottom: 15.5px;
  border-color: #d9d9d9;
}

.phone-number-error{
  transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.react-tel-input .form-control::placeholder {
  opacity: 0.6;
}

.phone-number-label{
  &::before{
    content: '* ';
    color: red;
  }
}

.success.form-control{
  border: 1px solid #1890ff;
}
.error.form-control {
  border: 1px solid red;
}

.default.form-control:hover {
  border: 1px solid #1890ff;
}

