.forgot__item {
    display: flex;
    justify-content: space-between;
}

.login__form {
    padding: 100px 0;
    margin: 0 auto;
    max-width: 700px;
    width: 100%;

    .btn_mb25{
        margin-bottom: 25px;
    }

    .btn {
        width: 100%;
        background-color: rgba(0, 0, 0, 0.85);
        color:#fff;
        outline: none;
        box-shadow: none;
    }

    .img {
        max-width: 200px;
    }
}

@media (max-width: 718px) {
    .login__form {
        min-width: 0;
        max-width: 700px;
    }
}

