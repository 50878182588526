.photos_scroll_component{
    width: 100%;
    overflow-x: hidden !important;
    padding: 5px;
}

.photo_info {
    font-size: smaller;
    font-weight: 800;
}

.photo_checkbox_wrapper{
    width: 100%;
    display: flex;
    justify-content: flex-start;
}

.photo_card_selected {
    background: rgba(29, 155, 209, 0.2);
}

.photo_card_blur {
    filter: blur(20px) grayscale(80%);
}

.photos_tab_element_center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.photos_modal_preview_wrapper .ant-modal-content {
    background: #333333;
    background-image: url("../../../img/smp-background-repeater.png");
    box-shadow: none;
    padding: 30px;
}

.photos_modal_preview_wrapper .mini-dashboard-card {
    background-color: #E4CC6A;
    border-color: #AD974E;

}

.photos_modal_preview_wrapper .ant-modal-close-icon {
    color: #fff;
    font-size: 30px;
}

.photos_modal_preview_content {
    position: relative;
}

.photos_modal_preview_text {
    color: #fff;
    background: rgba(23, 23, 23, 0.3);
    display: flex;
    text-align: center;
    font-size: 0.9em;
    font-weight: 800;
    position: absolute;
    z-index: 2;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
}

.photos_modal_preview_content .ant-image {
    width: 100%;
    height: 100%;
}

.photos_modal_preview_image {
    object-fit: contain;
    width: 100%;
    height: 100%;
    max-height: 85vh;
    box-shadow: 0 0 5px 1px rgba(34, 60, 80, 0.2);
}